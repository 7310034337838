<template>
<div class="row coming-soon align-items-end">
  <div class="col-12 col-md-7 ">
    <div class="resumen">
      <div class="title">
        <h4>{{ $t('PROPERAMENT') }}</h4>
      </div>
      <div class="events py-3" v-if="ultimsEvents">
        <ul v-for="(event,index) in ultimsEvents.slice(0,2)" v-bind:key="index">
          <li>
            <div class="pl-1">
              <span class="date" >{{ event.start.date | moment("D/M/YYYY")  }}</span>
              <span class="today" v-if=" isToday( new Date(event.start.date))"> {{ $t('AVUI')}}!!</span>
            </div>
            <div class="pl-3"><a href="#" class="lloc link">{{ event.summary }}</a></div>
          </li>
        </ul>
        <div class="pl-2 mt-2">
          <a href="#agenda" class="view-all-events" v-scroll-to="'#agenda'"> {{ $t('ver agenda') }}</a>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-5 d-flex justify-content-center mt-4 mt-md-0">
      <a class="programans btn-enviar"  v-scroll-to="'#contacte'"> {{ $t('programans') }}</a>
  </div>
</div>
</template>

<script>
export default {
  name: 'ComingSoon',
  props: {
    agenda: Object,
  },
  data() {
    return {
      propersBolos: [],
    };
  },
  computed: {
    ultimsEvents() {
      const propersBolos = [];
      if (this.agenda) {
        const today = new Date();
        const events = this.agenda.data;
        events.forEach((event) => {
          const data = new Date(event.start.date);
          if (data >= today || this.isToday(data)) {
            propersBolos.push((event));
          }
        });
        return propersBolos;
      }
      return null;
    },
  },
  methods: {
    isToday(date) {
      const today = new Date();
      return date.getDate() === today.getDate()
        && date.getMonth() === today.getMonth()
        && date.getFullYear() === today.getFullYear();
    },
  },
};
</script>
<style lang="scss">
  .today{
    color: #ff6633;
    animation: blinker .8s linear infinite;
  }

  @keyframes blinker {
    50% {
      opacity: 0.2;
    }
  }
</style>
<i18n>
  {
  "ca": {
    "programans": "Programa'ns",
    "ver agenda": "Veure tota l'agenda",
    "PROPERAMENT": "PROPERAMENT",
    "s3": "Qui cu qui què quina és un joc de connexions entre els artistes, una màquina i el públic. Tots els cossos vius tenen electricitat. Què passa si ens connectem?",
    "AVUI": "AVUI"
  },
  "es": {
    "programans": "Programanos",
    "ver agenda": "Ver toda la agenda",
    "PROPERAMENT": "PRÓXIMAMENTE",
    "s2": "Se enredan con cables, trepan a un mástil y dibujan círculos subidos en una bicicleta. Su complicidad les llevará a descubrir nuevos mundos dentro de las melodías que suena cuando dos personas se conectan.",
    "s3": "Qui cu qui què quina es un juego de conexiones entre los artistas, una máquina y el público. Todos los cuerpos vivos tienen electricidad. ¿Qué ocurre si nos conectamos?",
    "AVUI": "HOY"
  },
  "en": {
    "programans": "Schedule us",
    "ver agenda": "See all events",
    "PROPERAMENT": "SOON",
    "s2": "Se enredan con cables, trepan a un mástil y dibujan círculos subidos en una bicicleta. Su complicidad les llevará a descubrir nuevos mundos dentro de las melodías que suena cuando dos personas se conectan.",
    "s3": "Qui cu qui què quina es un juego de conexiones entre los artistas, una máquina y el público. Todos los cuerpos vivos tienen electricidad. ¿Qué ocurre si nos conectamos?",
    "AVUI": "TODAY"
  },
  "fr": {
    "programans": "Planifiez-nous",
    "ver agenda": "Voir tout l'agenda",
    "PROPERAMENT": "BIENTÔT",
    "s2": "Se enredan con cables, trepan a un mástil y dibujan círculos subidos en una bicicleta. Su complicidad les llevará a descubrir nuevos mundos dentro de las melodías que suena cuando dos personas se conectan.",
    "s3": "Qui cu qui què quina es un juego de conexiones entre los artistas, una máquina y el público. Todos los cuerpos vivos tienen electricidad. ¿Qué ocurre si nos conectamos?",
    "AVUI": "AUJOURD'HUI"
  }
  }
</i18n>
