<template>
  <div class="container-fluid">
    <div class="sinopsi container py-5" id="espectacle">
      <h1>{{ $t('spectacle_name') }} </h1>
      <div class="py-4">
        <p>
          {{ $t('s1') }}
        </p>
        <p>
          {{ $t('s2') }}
        </p>
        <p>
          {{ $t('s3') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Sinopsi',
  methods: {
    playSound(sound) {
      if (sound) {
        const audio = new Audio(sound);
        audio.play();
      }
    },
  },
};
</script>

<i18n>
{
  "ca": {
    "SINOPSI": "SINOPSI",
    "spectacle_name": "QUI CU QUI QUÈ QUINA",
    "s1": "Tots els cossos vius tenen electricitat. Què passa si ens connectem?",
    "s2": "S’emboliquen amb cables, s’enfilen a una perxa i dibuixen cercles sobre una bicicleta. La seva complicitat els portarà a descobrir nous mons en la melodia que sona quan dues persones estan connectades.",
    "s3": "Qui cu qui què quina és una aventura a la recerca d’una nova manera de relacionar-se. Un joc de connexions entre els artistes, una màquina i el públic."
  },
  "es": {
    "SINOPSI": "SINOPSI",
    "spectacle_name": "QUI CU QUI QUÈ QUINA",
    "s1": "Todos los cuerpos vivos tienen electricidad. Qué pasa si nos conectamos?",
    "s2": "Se enredan con cables, se suben a un mástil y dibujan círculos sobre una bicicleta. Su complicidad los llevará a descubrir nuevos mundos en la melodía que suena cuando dos personas están conectadas.",
    "s3": "Qui cu qui què quina es una aventura en busca de una nueva manera de relacionarse. Un juego de conexiones entre los artistas, una máquina y el público."
  },
  "en": {
    "SINOPSI": "SYNOPSI",
    "spectacle_name": "ELECTRIC FEEL",
    "s1": "All living bodies have electricity. What happens if they connect with each other? ",
    "s2": "They get entangled in wires, climb up a pole and draw circles on a bicycle. Their mutual understanding will lead them to discover new worlds amidst the melody that sounds when two persons are connected.",
    "s3": "Electric feel is an adventure looking for a new way to relate. A game of connections between the artists, a machine and the audience."
  },
  "fr": {
    "SINOPSI": "SYNOPSI",
    "spectacle_name": "ELECTRIC FEEL",
    "s1": "Tous les corps vivants ont de électricité. Qu'est-ce que passe si nous les relions? ",
    "s2": "Ils s'emmêlent avec des câbles, ils grimpent à une perche et ils dessinent des cercles sur un vélo. Leur complicité les portera à découvrir de nouveaux mondes au travers de la mélodie qui sonne quand deux personnes sont reliées.",
    "s3": "Electric feel est une aventure à la recherche d'une nouvelle façon de se lier. Un jeu de connexions entre les artistes, une machine et le public."
  }
}
</i18n>
