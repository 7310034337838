<template>
  <div class="container-fluid" id="video"  :class="{ 'active': active }">
    <div class="container position-relative">
      <div class="embed-container">
        <div class="preview">
          <div class="play" @click="play"><svg xmlns="http://www.w3.org/2000/svg" id="Capa_1" height="120px" viewBox="0 0 512 512" width="120px" class=""><g><g>
            <g>
              <circle cx="256" cy="256" fill="#ff6b6b" r="256" data-original="#00338C" class="" style="fill:#00338C" data-old_color="#ff6b6b"/>
              <path d="m512 256h-512c0 141.385 114.615 256 256 256s256-114.615 256-256z" fill="#ff1f3d" data-original="#00338C" class="" style="fill:#00338C" data-old_color="#ff1f3d"/>
              <g>
                <g>
                  <path d="m185.999 401c-2.333 0-4.673-.543-6.827-1.645-5.016-2.564-8.172-7.722-8.172-13.355v-260c0-5.633 3.156-10.791 8.171-13.355s11.044-2.103 15.611 1.195l180 130c3.905 2.82 6.218 7.344 6.218 12.16s-2.313 9.34-6.218 12.16l-180 130c-2.605 1.882-5.686 2.84-8.783 2.84z" fill="#f9f9f9" data-original="#FF6633" class="active-path" style="fill:#FF6633" data-old_color="#f9f9f9"/>
                </g>
              </g>
              <g>
                <path d="m171 256v130c0 5.633 3.156 10.791 8.171 13.355 2.154 1.102 4.495 1.645 6.827 1.645 3.097 0 6.179-.958 8.784-2.84l180-130c3.904-2.82 6.218-7.344 6.218-12.16z" fill="#e2dff4" data-original="#FF6633" class="active-path" style="fill:#FF6633" data-old_color="#e2dff4"/>
              </g>
            </g>
          </g></g> </svg>
          </div>
        </div>
        <vimeo-player
          class="player"
          :class="{ 'active': active }"
          player-width="640px"
          player-height="360px"
          ref="player"
          :controls=true
          @progress="progress"
          @ended="ended"
          :video-id="'384714538'"
        />
      </div>
      <div class="controls" :class="{ 'active': active }">
        <b-icon-play-fill
          :class="{ 'active': !isplay }"
          @click="resume"
          class="play">
        </b-icon-play-fill>
        <b-icon-fullscreen @click="openFullscreen" class="fullscreen"></b-icon-fullscreen>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Video',
  data() {
    return {
      isplay: false,
      active: false,
      videoprogress: 0,
    };
  },
  methods: {
    onReady() {
      this.playerReady = true;
    },
    play() {
      this.active = true;
      this.isplay = true;
      this.$refs.player.play();
      // this.openFullscreen();
    },
    resume() {
      this.isplay = true;
      this.$refs.player.play();
    },
    pause() {
      this.isplay = false;
      this.$refs.player.pause();
    },
    stop() {
      this.isplay = false;
      this.$refs.player.stop();
    },
    progress(event) {
      const percent = event.percent * 100;
      console.log(percent);
      this.videoprogress = percent;
    },
    ended() {
      this.active = false;
      this.closeFullscreen();
    },
    openFullscreen() {
      const elem = document.getElementById(this.$refs.player.elementId);
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) { /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
        elem.msRequestFullscreen();
      }
    },
    closeFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE/Edge */
        document.msExitFullscreen();
      }
    },
  },
};
</script>
