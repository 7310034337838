<template>
  <div id="contacte" class="contacte container-fluid">
    <div class="container py-5">
      <h2>{{ $t('Contacte') }}</h2>
      <div class="row dades my-4">
        <div class="col-12 col-md-6">
          <h4>{{ $t('Contractació') }}</h4>
          <div class="mt-3">
            <span>Alina Ventura</span><br/>
            <span>info@lamaleta.cat</span><br/>
            <span>+34 652 365 493</span>
          </div>
        </div>
        <div class="col-12 col-md-6 la-maleta">
          <img
            class=""
            src="../assets/logos/la-maleta-dels-espectacles.png"
            alt="image slot">
        </div>
      </div>

      <div class="row dades my-4">
        <div class="col-12 col-md-6">
          <div>
            <h4>{{ $t('Companyia') }}</h4>
            <div class="mt-3">
              <span>hola@cianomprovisional.com</span><br/>
              <span>+34 607 945 540</span><br/>
              <span>+34 637 592 849</span>
            </div>
          </div>
          <div class="mt-3">
            <h4>{{ $t('SEGUEIX-NOS') }}</h4>
            <div class="mt-3">
              <a href="https://www.facebook.com/cianomprovisional" target="_blank" title="Facebook Cia Nom provisional">
                <img class="mt-4 mr-5" src="../assets/logos/facebook.png"/>
              </a>
              <a href="https://www.instagram.com/cianomprovisional" target="_blank" title="Instagram Cia Nom provisional">
                <img class="mt-4" src="../assets/logos/instagram.png"/>
              </a>
            </div>
          </div>

        </div>
        <div class="col-12 col-md-6 text-center d-none d-md-block">
          <img
            class="border-img"
            src="../assets/Contacte.jpg"
            alt="image slot">
        </div>
      </div>
      <section class="form mt-5" id="formulari">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-3">
            <div class="field" :class="{ 'form-group--error': $v.email.$error }">
              <label for="email">Email</label>
              <input type="email" id="email" v-model="email" placeholder="exemple@correu.com">
              <div class="error" v-if="!$v.email.required">{{ $t('El camp es necesari!') }}</div>
              <div class="error" v-if="!$v.email.email">
                {{ $t('El correu ha de ser un válid!') }}
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 col-md-8 col-lg-5">
            <div class="field" :class="{ 'form-group--error': $v.missatge.$error }">
              <label for="comentari">{{ $t('Missatge') }}</label>
              <textarea id="comentari"
                        v-model="missatge"
                        :placeholder="$t('Ens vols contractar?')">
              </textarea>
              <div class="error" v-if="!$v.missatge.required">{{ $t('El camp es necesari!') }}</div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-5 text-right">
            <button class="btn btn-enviar" @click="submit">{{ $t('ENVIAR') }} >>></button>
          </div>
        </div>
      </section>

    </div>
  </div>
</template>

<script>

import { required, email } from 'vuelidate/lib/validators';

const emailjs = require('emailjs-com');

export default {
  name: 'Contacte',
  data() {
    return {
      email: '',
      missatge: '',
      emailjs: null,
    };
  },
  created() {
    this.emailjs = emailjs.init('user_JLQwkALq1PdC3DyBijA2x');
  },
  validations: {
    email: {
      required,
      email,
    },
    missatge: {
      required,
    },
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log('error validation');
      } else {
        // eslint-disable-next-line no-shadow
        const email = {
          name: 'Gerard',
          email: this.email,
          message: this.missatge,
        };
        emailjs.send('gmail', 'template_BdKkleqE', email, 'user_JLQwkALq1PdC3DyBijA2x')
          // eslint-disable-next-line no-unused-vars
          .then((result) => {
            this.showMsgOk('Missatge enviat', 'Gràcies pel missatge!</br></br> Aviat ens posarem en contacte amb tu! </br></br> Bip bip bip...', 'Gràcies');
            // eslint-disable-next-line no-unused-vars
          }, (error) => {
            this.showMsgOk('Error @!#$!', 'Ups... la màquina ha fallat...</br></br> Pots contactar amb nosaltres a hola@cianomprovisional.com', 'Tancar');
          });
      }
    },
    showMsgOk(title, message, okTitle) {
      const h = this.$createElement;
      // Using HTML string
      const titleVNode = h('div', { domProps: { innerHTML: title } });
      // More complex structure
      const messageVNode = h('div', { class: ['foobar'] }, [
        h('p', { domProps: { innerHTML: message } }),
      ]);
      // We must pass the generated VNodes as arrays
      this.$bvModal.msgBoxOk([messageVNode], {
        title: [titleVNode],
        buttonSize: 'md',
        centered: true,
        size: 'md',
        okTitle,
      });
    },
  },
};
</script>

<i18n>
  {
  "ca": {
    "Contacte": "Contacte",
    "Contractació": "Distribució",
    "Companyia": "Companyia",
    "SEGUEIX-NOS": "SEGUEIX-NOS",
    "El camp es necesari!" : "El camp es necesari!",
    "El correu ha de ser un válid!" :"El correu ha de ser un válid!",
    "Missatge": "Missatge",
    "Ens vols contractar?" : "Ens vols contractar?",
    "ENVIAR": "ENVIAR"
  },
  "es": {
    "Contacte": "CONTACTO",
    "Contractació": "Distribución",
    "Companyia": "Compañía",
    "SEGUEIX-NOS": "SÍGUENOS",
    "El camp es necesari!" : "El campo es necesario!",
    "El correu ha de ser un válid!" :"El correo debe ser válido!",
    "Missatge": "Mensaje",
    "Ens vols contractar?" : " ¿Quieres contractarnos?",
    "ENVIAR": "ENVIAR"
  },
  "en": {
    "Contacte": "CONTACT",
    "Contractació": "Manager",
    "Companyia": "Company",
    "SEGUEIX-NOS": "FOLLOW US",
    "El camp es necesari!" : "This field is mandatory!",
    "El correu ha de ser un válid!" :"Mail must be valid!",
    "Missatge": "Message",
    "Ens vols contractar?" : "Do you want to hire us?",
    "ENVIAR": "SEND"
  },
  "fr": {
    "Contacte": "CONTACT",
    "Contractació": "Distribution",
    "Companyia": "Compagnie",
    "SEGUEIX-NOS": "SUIVEZ-NOUS",
    "El camp es necesari!" : " Ce champ est obligatoire!",
    "El correu ha de ser un válid!" :"L'email doit être valide!",
    "Missatge": "Message",
    "Ens vols contractar?" : "Vous souhaitez nous embaucher?",
    "ENVIAR": "ENVOYER"
  }
  }
</i18n>
