<template>
  <div id="footer" class="footer container-fluid">
    <div class="container  py-5">
      <h4>
        {{ $t('Amb el suport de') }}
      </h4>
      <div class="row my-3 colaboracio">
        <div class="col-12 col-sm-6 col-md-3 py-3 my-auto">
          <img
            class="d-block class-name"
            src="../assets/logos/amb-el-suport/interreg-poctefa/logo-interreg-poctefa-blanc.png"
            alt="image slot">
        </div>
        <div class="col-12 col-sm-6 col-md-3 py-3 my-auto">
          <img
            class="d-block class-name"
            src="../assets/logos/amb-el-suport/de-mar-a-mar/dMaM-blanc.png"
            alt="image slot">
        </div>
        <div class="col-12 col-sm-6 col-md-3 py-3 my-auto">
          <img
            class="d-block class-name"
            src="../assets/logos/amb-el-suport/la-central/lacentraldelcirc-logo-blanc.png"
            alt="image slot">
        </div>
        <div class="col-12 col-sm-6 col-md-3 py-3 my-auto">
          <img
            class="d-block class-name hameka"
            src="../assets/logos/amb-el-suport/hameka/hameka-logo-blanc.png"
            alt="image slot">
        </div>
        <div class="col-12 col-sm-6 col-md-3 py-3 my-auto">
          <img
            class="d-block class-name"
            src="../assets/logos/en-colaboracio/cronopis/cronopis-logo-blanc.png"
            alt="image slot">
        </div>
        <div class="col-12 col-sm-6 col-md-3 py-3 my-auto">
          <img
            class="d-block img-responsive center-block"
            src="../assets/logos/en-colaboracio/lestruch/logotip-lestruch-blanc.png"
            alt="image slot">
        </div>
        <div class="col-12 col-sm-6 col-md-3 py-3 my-auto">
          <img
            class="d-block class-name"
            src="../assets/logos/en-colaboracio/ajuntament-sabadell/ajuntament_sabadell_blanc.png"
            alt="image slot">
        </div>
        <div class="col-12 col-sm-6 col-md-3 py-3 my-auto">
        <img
          class="d-block class-name"
          src="../assets/logos/amb-el-suport/kreas/Logo-Kreas-blanc.png"
          alt="image slot">
      </div>
      <div class="col-12 col-sm-6 col-md-3 py-3 my-auto">
        <img
          class="d-block class-name"
          src="../assets/logos/amb-el-suport/ajuntament-girona/logo_ajgirona_blanc.png"
          alt="image slot">
      </div>
      <div class="col-12 col-sm-6 col-md-3 py-3 my-auto">
          <img
            class="d-block class-name"
            src="../assets/logos/en-colaboracio/cultura-mataro/logo-cultura-mataro-blanc.png"
            alt="image slot">
      </div>
      <div class="col-12 col-sm-6 col-md-3 py-3 my-auto">
        <img
          class="d-block img-responsive center-block"
          src="../assets/logos/en-colaboracio/ajuntament-mataro/Ajuntament_Mataro_blanc.png"
          alt="image slot">
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>
<i18n>
  {
   "ca": {
     "Amb el suport de": "Amb el suport de"
   },
   "es": {
     "Amb el suport de": "CON EL APOYO DE"
   },
   "en": {
     "Amb el suport de": "AVEC LE SOUTIEN DE"
   },
   "fr" : {
     "Amb el suport de": "WITH THE SUPPORT"
   }
 }
</i18n>
