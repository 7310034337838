var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"agenda container-fluid",attrs:{"id":"agenda"}},[_c('div',{staticClass:"container py-5"},[_c('h2',[_vm._v(_vm._s(_vm.$t('AGENDA')))]),_c('div',{staticClass:"row"},[(_vm.agenda.length)?_c('div',{staticClass:"col-12 col-md-7 col-lg-8"},_vm._l((_vm.agenda),function(item,indexX){return _c('ul',{key:indexX},[_c('li',[_c('span',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
            el: `#any${item.year}`,
            container: 'body',
            duration: 500,
            easing: 'linear',
            offset: -120,
            force: true,
            cancelable: true,
            x: false,
            y: true
            }),expression:"{\n            el: `#any${item.year}`,\n            container: 'body',\n            duration: 500,\n            easing: 'linear',\n            offset: -120,\n            force: true,\n            cancelable: true,\n            x: false,\n            y: true\n            }"}],staticClass:"any",on:{"click":function($event){return _vm.toggleAny(item.year)}}},[_vm._v(_vm._s(item.year))]),(item.data)?_c('ul',{staticClass:"bolos",class:{ 'hidden': indexX > 0 },attrs:{"id":"any" + item.year}},_vm._l((_vm.reverse(item.data)),function(data,index){return _c('li',{key:index},[_c('span',{staticClass:"data pr-4"},[_vm._v(_vm._s(_vm._f("moment")(data.start.date,"D/M/YYYY")))]),_c('span',{staticClass:"lloc"},[_vm._v(_vm._s(data.summary))])])}),0):_vm._e()])])}),0):_vm._e(),_vm._m(0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-md-5 col-lg-4 d-none d-md-block"},[_c('img',{staticClass:"border-img",attrs:{"id":"imagen-agenda","src":require("../assets/embolic.jpg"),"alt":"image slot"}})])
}]

export { render, staticRenderFns }