<template>
  <div>
    <div id='btn-menu-mobile' class="btn-menu-mobile d-block d-md-none">
      <b-button v-b-toggle.menu-mobile :class="{menuSecondayColor:menuSecondayColor}">
        <b-icon-list></b-icon-list>
      </b-button>
    </div>

    <b-sidebar id="menu-mobile" class="b-sidebar-cia" backdrop shadow v-model="visible">
      <div class="px-3 py-2">
        <b-navbar-nav>
          <b-nav-item href="#" @click="goTo('#inici')">INICI</b-nav-item>
          <b-nav-item href="#" @click="goTo('#espectacle')">{{ $t('espectacle') }}</b-nav-item>
          <b-nav-item href="#" @click="goTo('#companyia')">{{ $t('companyia') }}</b-nav-item>
          <b-nav-item href="#" @click="goTo('#agenda')">{{ $t('agenda') }}</b-nav-item>
          <b-nav-item href="#" @click="goTo('#contacte')">{{ $t('contacte') }}</b-nav-item>
        </b-navbar-nav>
      </div>
    </b-sidebar>
  </div>

</template>

<script>
export default {
  name: 'MenuResponsive',
  props: {
    msg: String,
  },
  data() {
    return {
      visible: false,
      options: {
        el: '#espectacle',
        container: 'body',
        duration: 500,
        easing: 'linear',
        offset: 0,
        force: true,
        cancelable: true,
        onStart(element) {
          console.log(element);
        },
        onDone(element) {
          console.log(element);
        },
        onCancel() {
          // scrolling has been interrupted
        },
        x: false,
        y: true,
      },
      menuSecondayColor: false,
      sections: {
        companyia: {
          top: 0,
          bottom: 0,
        },
        agenda: {
          top: 0,
          bottom: 0,
        },
        footer: {
          top: 0,
          bottom: 0,
        },
      },
    };
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {

  },
  methods: {
    goTo(value) {
      this.visible = false;
      this.$scrollTo(value, 200, this.options);
    },
    handleScroll() {
      const position = window.scrollY;

      this.sections.companyia.top = document.getElementById('companyia').offsetTop;
      this.sections.companyia.bottom = this.sections.companyia.top + document.getElementById('companyia').offsetHeight;

      this.sections.agenda.top = document.getElementById('agenda').offsetTop;
      this.sections.agenda.bottom = this.sections.agenda.top + document.getElementById('agenda').offsetHeight;

      this.sections.footer.top = document.getElementById('footer').offsetTop;
      this.sections.footer.bottom = this.sections.footer.top + document.getElementById('footer').offsetHeight;

      if ((position >= this.sections.companyia.top && position <= this.sections.companyia.bottom)
        || (position >= this.sections.agenda.top && position <= this.sections.agenda.bottom)
        || (position >= this.sections.footer.top && position <= this.sections.footer.bottom)
      ) {
        this.menuSecondayColor = true;
      } else {
        this.menuSecondayColor = false;
      }
      // document.getElementById('btn-menu-mobile').offsetTop;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.menuSecondayColor{
  background: #00338c;
}
</style>
<i18n>
  {
  "ca": {
  "espectacle": "Espectacle"
  },
  "es": {
  "espectacle": "Espectaculo"
  },
  "en": {
  "espectacle": "Spectacle"
  }
  }
</i18n>
