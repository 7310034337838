<template>
  <div id="agenda" class="agenda container-fluid">
    <div class="container py-5">
      <h2>{{ $t('AGENDA') }}</h2>
      <div class="row">
        <div class="col-12 col-md-7 col-lg-8" v-if="agenda.length">
          <ul v-for="(item, indexX) in agenda" v-bind:key="indexX">
            <li>
              <span class="any" @click="toggleAny(item.year)" v-scroll-to="{
              el: `#any${item.year}`,
              container: 'body',
              duration: 500,
              easing: 'linear',
              offset: -120,
              force: true,
              cancelable: true,
              x: false,
              y: true
              }" >{{ item.year }}</span>
              <ul v-if="item.data" class="bolos" :class="{ 'hidden': indexX > 0 }" :id='"any" + item.year'>
                <li v-for="(data, index) in reverse(item.data)" v-bind:key="index">
                  <span class="data pr-4">{{ data.start.date | moment("D/M/YYYY") }}</span>
                  <span class="lloc">{{ data.summary }}</span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="col-12 col-md-5 col-lg-4 d-none d-md-block">
          <img
            id="imagen-agenda"
            class="border-img"
            src="../assets/embolic.jpg"
            alt="image slot">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Agenda',
  props: {
    agenda: Array,
  },
  data() {
    return {
      options: {
        container: '#agenda',
        easing: 'ease-in',
        offset: -60,
        force: true,
        cancelable: true,
        x: false,
        y: true,
      },
    };
  },
  methods: {
    reverse(array) {
      return [...array].reverse();
    },
    toggleAny(any) {
      const menu = document.querySelectorAll('.bolos');

      const optMenu = document.getElementById(`any${any}`);
      if (optMenu.classList.contains('hidden')) {
        optMenu.classList.remove('hidden');
      }

      menu.forEach((el) => {
        // eslint-disable-next-line eqeqeq
        if (el.id != `any${any}`) {
          el.classList.add('hidden');
        }
      });
    },
  },
};
</script>

<i18n>
  {
  "ca": {
    "AGENDA" : "AGENDA"
  },
  "es": {
    "AGENDA" : "AGENDA"
  },
  "en": {
    "AGENDA" : "SCHEDULE"
  },
  "fr": {
    "AGENDA" : "L'AGENDA"
  }
  }
</i18n>

<style lang="scss">
#agenda ul .hidden {
  display: none;
}
  .any{
    cursor: pointer;
  }
.bolos {
  -webkit-animation: fadein .4s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein .4s; /* Firefox < 16 */
  -ms-animation: fadein .4s; /* Internet Explorer */
  -o-animation: fadein .4s; /* Opera < 12.1 */
  animation: fadein .4s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

</style>
