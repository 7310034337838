import vueVimeoPlayer from 'vue-vimeo-player';
import Vue from 'vue';
import Vuelidate from 'vuelidate';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import VueScrollTo from 'vue-scrollto';
import VuePreview from 'vue-preview';
import VueLazyload from 'vue-lazyload';
import App from './App.vue';
import router from './router';

import './scss/custom.scss';
import i18n from './i18n';

Vue.use(VueLazyload);
Vue.use(Vuelidate);
Vue.use(VueScrollTo);
Vue.use(require('vue-moment'));

// You can also pass in the default options
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease-in-out',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone(element) {
    console.log(element);
  },
  onCancel: false,
  x: false,
  y: true,
});

// defalut install
Vue.use(VuePreview);

// with parameters install
// eslint-disable-next-line no-undef
Vue.use(VuePreview, {
  mainClass: 'pswp--minimal--dark',
  barsSize: { top: 0, bottom: 0 },
  captionEl: false,
  fullscreenEl: false,
  shareEl: false,
  bgOpacity: 0.85,
  tapToClose: true,
  tapToToggleControls: false,
});

Vue.use(vueVimeoPlayer);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
