<template>
  <section class="fitxa py-5">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h4>
            {{ $t('FITXA ARTÍSTICA') }}
          </h4>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-5 llistat-fitxa">
          <div class="item py-2">
            <span class="titol">{{ $t('Títol') }}: </span>
            <span class="text">{{ $t('spectacle_name') }}</span>
          </div>
          <div class="item py-2">
            <span class="titol">{{ $t('Idea original') }}: </span>
            <span class="text">Joan Pallàs {{ $t('i') }} Eva camps</span>
          </div>
          <div class="item py-2">
            <span class="titol">{{ $t('Intèrprets') }}: </span>
            <span class="text">Joan Pallàs {{ $t('i') }} Eva camps</span>
          </div>
          <div class="item py-2">
            <span class="titol">{{ $t('Direcció') }}: </span>
            <span class="text">cia Nom Provisional</span>
          </div>
          <div class="item py-2">
            <span class="titol">{{ $t('Mirada externa') }}: </span>
            <span class="text">Anna Pascual {{ $t('i') }} Tomeu Amer</span>
          </div>
          <div class="item py-2">
            <span class="titol">{{ $t('Música original') }}: </span>
            <span class="text">Joan Cot</span>
          </div>
          <div class="item py-2">
            <span class="titol">{{ $t('Vestuari') }}: </span>
            <span class="text">Anna Izquierdo</span>
          </div>
          <div class="item py-2">
            <span class="titol">{{ $t('Fotografia') }}: </span>
            <span class="text">Marta GC</span>
          </div>
          <div class="item py-2">
            <span class="titol">{{ $t('Vídeo') }}: </span>
            <span class="text">Julian Waisbord</span>
          </div>
          <div class="item py-2">
            <span class="titol">{{ $t('Agraïments') }}: </span>
            <span class="text">Àngel de Miguel {{ $t('i') }} Gerard Fortea</span>
          </div>
          <div class="mt-5">
            <a :href="$t('dossier')" class="link" target="_blank" :title="$t('Dossier artístic Qui cu qui que quina')" >
              <span id="d">{{ $t('Dossier artístic') }} >>></span>
              <b-tooltip target="d" placement="right" offset="0" custom-class="cia-tooltip">{{ $t('Dossier artístic qui cu qui que quina') }}</b-tooltip>
            </a>
          </div>
          <div class="mt-5">
            <a :href="$t('fitxa')" class="link" target="_blank" :title="$t('Fitxa tècnica Qui cu qui que quina')">
              <span id="ft">{{ $t('Fitxa tècnica') }} >>></span>
              <b-tooltip target="ft" placement="right" offset="0" custom-class="cia-tooltip">{{ $t('Fitxa tècnica qui cu qui que quina') }}</b-tooltip>
            </a>
          </div>
        </div>
        <div class="col-12 col-md-7 text-center d-none d-md-block">
          <img
            class="border-img"
            src="../assets/fitxa.jpg"
            alt="image slot">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Fitxa',
};
</script>
<style lang="scss">

</style>
<i18n>
{
  "ca": {
    "FITXA ARTÍSTICA": "FITXA ARTÍSTICA",
    "Títol": "Títol",
    "spectacle_name": "Qui cu qui que quina",
    "Idea original" : "Idea original",
    "Intèrprets" : "Intèrprets",
    "Direcció": "Direcció",
    "Mirada externa":"Mirada externa",
    "Música original":"Disseny sonor",
    "Vestuari":"Vestuari",
    "Fotografia":"Fotografia",
    "Vídeo":"Vídeo",
    "Dossier artístic": "Dossier artístic",
    "Fitxa tècnica": "Fitxa tècnica",
    "dossier": "/files/CAT-Dossier-Qui-cu-qui-que-quina.pdf",
    "fitxa": "/files/CAT-Fitxa-tecnica-Qui-cu-qui-que-quina.pdf",
    "Dossier artístic qui cu qui que quina": "Descarrega't el Dossier artístic de Qui cu qui que quina",
    "Fitxa tècnica qui cu qui que quina": "Descarrega't la Fitxa tècnica de Qui cu qui que quina",
    "Agraïments": "Agraïments",
    "i": "i"
},
  "es": {
    "FITXA ARTÍSTICA": "Ficha artística",
    "Títol": "Título",
    "spectacle_name": "Qui cu qui que quina",
    "Idea original" : "Idea Original",
    "Intèrprets" : "Intérpretes",
    "Direcció":"Dirección",
    "Mirada externa":"Mirada Externa",
    "Música original":"Diseño sonoro",
    "Vestuari":"Vestuario",
    "Fotografia":"Fotografía",
    "Vídeo":"Video",
    "Dossier artístic": "Dossier artístico",
    "Fitxa tècnica": "Ficha técnica",
    "dossier": "/files/ES-Dossier-Qui-cu-qui-que-quina.pdf",
    "fitxa": "/files/ES-Fitxa-tecnica-Qui-cu-qui-que-quina.pdf",
    "Dossier artístic qui cu qui que quina": "Descarga el Dossier artístico de Qui cu qui que quina",
    "Fitxa tècnica qui cu qui que quina": "Descarga la Ficha técnica de Qui cu qui que quina",
    "Agraïments": "Agraïments",
    "i": "y"
  },
  "en": {
    "FITXA ARTÍSTICA": "ARTISTIC FACT SHEET",
    "Títol": "Title",
    "spectacle_name": "Electric feel",
    "Idea original" : "Original idea",
    "Intèrprets" : "Performers",
    "Direcció":"Direction",
    "Mirada externa":"External view",
    "Música original":"Sound design",
    "Vestuari":"Costume design",
    "Fotografia":"Photography",
    "Vídeo":"Video",
    "Dossier artístic": "Artistic dossier",
    "Fitxa tècnica": "Technical fact sheet",
    "dossier": "/files/EN-Dossier-Electric-Feel.pdf",
    "fitxa": "/files/EN-Technical-Fact-Sheet-ElectricFeel.pdf",
    "Dossier artístic qui cu qui que quina": "Download the artistic Dossier of Electric feel",
    "Fitxa tècnica qui cu qui que quina": "Download the technical sheet of Electric feel",
    "Agraïments": "With thanks to",
    "i": "&"
  },
  "fr": {
    "FITXA ARTÍSTICA": "FICHE ARTISTIQUE",
    "Títol": "Titre",
    "spectacle_name": "Electric feel",
    "Idea original" : "Idée originale",
    "Intèrprets" : "Interprètes",
    "Direcció":"Direction",
    "Mirada externa":"Regard externe",
    "Música original":"Design sonore",
    "Vestuari":"Costumes",
    "Fotografia":"Photographie",
    "Vídeo":"Vidéo",
    "Dossier artístic": "Dossier artistique",
    "Fitxa tècnica": "Fiche technique",
    "dossier": "/files/FR-Dossier-Electric-Feel.pdf",
    "fitxa": "/files/FR-Fiche-Technique-Electric-Feel.pdf",
    "Dossier artístic qui cu qui que quina": "Télécharger le dossier artistique de Electric feel",
    "Fitxa tècnica qui cu qui que quina": "Télécharger la fiche technique de Electric feel",
    "Agraïments": "Remerciements",
    "i": "et"
  }
}
</i18n>
