<template>
  <div >
    <div class="menu">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <b-navbar variant="cia" class="d-none d-md-block">
              <b-collapse id="nav-collapse" is-nav class="d-none d-md-block">
                <b-navbar-nav>
                  <b-nav-item href="#" v-scroll-to="'#espectacle'">
                  {{ $t('espectacle') }}
                </b-nav-item>
                  <b-nav-item href="#" v-scroll-to="'#companyia'">{{ $t('companyia') }}</b-nav-item>
                  <b-nav-item href="#" v-scroll-to="'#agenda'">{{ $t('agenda') }}</b-nav-item>
                  <b-nav-item href="#" v-scroll-to="'#contacte'">{{ $t('contacte') }}</b-nav-item>
                </b-navbar-nav>
              </b-collapse>
            </b-navbar>
          </div>
        </div>
      </div>
    </div>
    <LanguageSelector @stiky="handleScroll()"></LanguageSelector>
  </div>

</template>

<script>

import LanguageSelector from './LanguageSelector.vue';

export default {
  name: 'Menu',
  props: {
    msg: String,
  },
  data() {
    return {
      stickyEnabled: false,
      menuPosition: 0,
    };
  },
  components: {
    LanguageSelector,
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrollPosition = window.scrollY;
      if (this.scrollPosition >= 25) {
        this.stickyEnabled = true;
      } else {
        this.stickyEnabled = false;
      }
      this.menuPosition = document.getElementById('btn-menu-mobile').offsetTop;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .menu{
    transition: all 0.3s ease-in-out;
  }
  .navbar{
    transition: all 0.3s ease-in;
  }
  .menusticky{
    position: fixed!important;
    height: 50px;
    z-index: 99999;
    width: 100%;
    & .navbar{
      height: 45px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  @keyframes example {
    0%   {background-color: red;}
    25%  {background-color: yellow;}
    50%  {background-color: blue;}
    100% {background-color: green;}
  }
</style>
<i18n>
   {
    "ca": {
      "espectacle": "Espectacle",
      "companyia": "companyia",
      "agenda": "agenda",
      "contacte" : "contacte"
    },
    "es": {
      "espectacle": "Espectaculo",
      "companyia": "compañia",
      "agenda": "agenda",
      "contacte" : "contacto"
    },
    "en": {
      "espectacle": "SHOW",
      "companyia": "COMPANY",
      "agenda": "AGENDA",
      "contacte" : "CONTACT"
    },
    "fr" : {
      "espectacle": "SPECTACLE",
      "companyia": "COMPAGNIE",
      "agenda": "AGENDA",
      "contacte" : "CONTACT"
    }
  }
</i18n>
