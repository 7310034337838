<template>
  <div class="container-fluid" id="galeria">
    <div class="row no-gutters">
      <div class="col-6" v-for="(imatge, index) in gallery" v-bind:key="index">
        <picture>
          <img
º            class="d-block imatge"
            @mouseover="test(imatge)"
            @mouseout="test2(imatge)"
            width="100%"
            v-lazy="imatge.path"
            alt="cia Nom provisional">
        </picture>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Galeria',
  props: {
    galeria: { Type: Array },
  },
  data() {
    return {
      gallery: this.galeria,
    };
  },
  methods: {
    handleClose() {
      console.log('close event');
    },
    test(imagen) {
      if (imagen.hover) {
        // eslint-disable-next-line no-param-reassign
        imagen.path = imagen.hover;
      }
    },
    test2(imagen) {
      if (imagen.hover) {
        // eslint-disable-next-line no-param-reassign
        imagen.path = imagen.original;
      }
    },
  },
};
</script>
