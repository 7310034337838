<template>
  <div id="companyia" class="companyia container-fluid primary-bg">
    <div class="container py-5">
      <h2 class="title">{{ $t('LA COMPANYIA') }}</h2>
      <p style="text-align: justify">
        {{ $t('companyia_1') }}
      </p>
      <p style="text-align: justify">
        {{ $t('companyia_2') }}
      </p>
      <div class="row my-5">
        <div class="col-12 col-md-6 order-1 order-md-0" style="text-align: justify">
          <p v-html="$t('eva_1')"></p>
          <p v-html="$t('eva_2')"></p>
          <p v-html="$t('eva_3')"></p>
        </div>
        <div class="col-12 col-md-6 order-0 order-md-1 mb-4 mb-md-0">
          <div>
            <span class="position-absolute text-uppercase space-mono nom">Eva camps</span>
            <img
              class="d-block border-img"
              width="100%"
              src="../assets/companyiaEVA.jpg"
              alt="image slot">
          </div>

        </div>
      </div>
      <div class="row my-5">
        <div class="col-12 col-md-6 img order-0 order-md-0  mb-4 mb-md-0">
          <div class="position-relative">
            <span class="position-absolute text-uppercase space-mono nom">Joan Pallàs</span>
            <img
              class="d-block border-img"
              width="100%"
              src="../assets/companyiaJOAN.jpg"
              alt="image slot">
          </div>

        </div>
        <div class="col-12 col-md-6 order-md-1 my-auto" style="text-align: justify">
          <p>{{ $t('joan_1') }}</p>
          <p>{{ $t('joan_2') }}</p>
          <p>{{ $t('joan_3') }}</p>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12 text-center">
          <img
            class="zirkolika"
            src="../assets/logos/zirkolika_blue.svg"
            alt="image">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Companyia',
};
</script>

<i18n>
  {
  "ca": {
    "LA COMPANYIA": "LA COMPANYIA",
    "companyia_1": "L’Eva i en Joan es coneixen a l’escola de circ Rogelio Rivel de Barcelona. En acabar la formació decideixen llançar-se a crear la seva companyia. Tots dos s’especialitzen en perxa xinesa i junts hi han desenvolupat el seu propi llenguatge.",
    "companyia_2": "Qui cu qui què quina és el seu primer espectacle que barreja el circ amb un món ple de botons i cables que provoquen curtcircuits i connexions impensables.",
    "eva_1" : "Després d’uns anys de formació en dansa contemporània, clàssica, jazz i hip-hop decideix fer el salt al món del circ. A banda de la perxa xinesa, també s’enfila com a àgil al damunt d’un portor al manillar d’una bicicleta acrobàtica.",
    "eva_2" : "També treballa com a acròbata a l’espectacle Iter 2.0. de la companyia Infra·poètic·circ. Així com amb la cia. Faltan7 i el seu espectacle Express.",
    "eva_3" : "A més a més també és formadora de circ i col·labora activament a l’Associació de les Arts del Circ Cronopis de Mataró.",
    "joan_1" : "Des de ben petit li deien que l’haurien de portar al circ, pero no és fins després de llicenciar-se en Publicitat i Relacions Públiques que comença la seva formació circense. Tot i el seu amor incondicional a la perxa xinesa també fa de portor al damunt d’una bicicleta.",
    "joan_2" : "Habitualment col·labora amb la companyia Planeta Trampolí i també fa d’intèrpret a l’espectacle Express de la cia. Faltan7.",
    "joan_3" : "També treballa com a formador de circ per infants i adults, i col·labora activament a l’Associació de les Arts del Circ Cronopis de Mataró."
  },
  "es": {
    "LA COMPANYIA": "LA COMPAÑIA",
    "companyia_1": "Eva y en Joan se conocen en la escuela de circo Rogelio Rivel de Barcelona. Al acabar la formación deciden lanzarse a crear su compañía. Los dos se especializan en mástil chino y juntos han desarrollado su propio lenguaje.",
    "companyia_2": "Qui cu qui què quina es su primer espectáculo que mezcla el circo con un mundo lleno de botones y cables que provocan cortocircuitos y conexiones impensables.",
    "eva_1" : "Después de unos años de formación en danza contemporánea, clásica, jazz e hip hop decide hacer el salto al mundo del circo. Además del mástil chino, también se sube como ágil encima de un portor al manillar de una bicicleta acrobática.",
    "eva_2" : "También trabaja como acróbata en el espectáculo Iter 2.0. de la compañía Infra·Poètic·Circ. Así como con la cía Faltan7 y su espectáculo Express.",
    "eva_3" : "Además también es formadora de circo y colabora activamente a la Asociación de las Artes del Circ Cronopis de Mataró.",
    "joan_1" : "Desde muy pequeño le decían que lo tendrían que llevar al circo, pero no es hasta después de licenciarse en Publicidad y Relaciones Públicas que empieza su formación circense. Y aun con su amor incondicional por el mástil chino también hace de portor encima de una bicicleta.",
    "joan_2" : "Habitualmente colabora con la compañía Planeta Trampolín y también hace de intérprete al espectáculo Express de la cía Faltan7.",
    "joan_3" : "También trabaja como formador de circo por niños y adultos, y colabora activamente en la Asociación de las Artes del Circo Cronopis de Mataró."
  },
  "en": {
    "LA COMPANYIA": "THE COMPANY",
    "companyia_1": "Eva and Joan get to know each other at the circus school Rogelio Rivel of Barcelona. After finishing their training they decide to create their own company. Both of them specialize in chinese pole and together they have developed their own language.",
    "companyia_2": "Electric feel is their first show with a world full of buttons and wires that provoke unthinkable short-circuits and connections.",
    "eva_1" : "After an initial year of training in contemporary and classical dance, jazz and hip-hop she decides to take the leap into the world of circus. Besides the chinese pole she also performs as a flyer on top of her catcher who’s steering an acrobatic bicycle.",
    "eva_2" : "She also works as an acrobat in the show Iter 2.0. of the company Infra·poètic·circ, as well as with the cia. Faltan7 in their show Express.",
    "eva_3" : "Moreover she is a circus trainer and collaborates actively with the “Associació de les Arts del Circ Cronopis de Mataró”.",
    "joan_1" : "Since his early childhood he was told that they should bring him to the circus, but it’s only after having completed a degree in Advertising and Public Relations that he starts his circus training. In spite of his unconditional love towards the chinese pole, he is also a catcher mounted on a bicycle.",
    "joan_2" : "He habitually collaborates with the company Planeta Trampolí and he is also a performer of the show Express of cia. Faltan7.",
    "joan_3" : "He also works as a circus trainer for kids and adults and collaborates actively with the “Associació de les Arts del Circ Cronopis de Mataró”."
  },
  "fr": {
  "LA COMPANYIA": "LA COMPAGNIE",
    "companyia_1": "Eva et Joan se connaissent à l’école de cirque Rogelio Rivel de Barcelone. À la fin de leur formation ils décident de se lancer et créent leur propre compagnie. Tous les deux se spécialisent en mât chinois et ensemble ils y ont développés leur propre langage.",
    "companyia_2": "Electric feel est leur premier spectacle qui mélange le cirque avec un monde plein de boutons et de câbles qui provoquent des court-circuits et des connections impensables.",
    "eva_1" : "Après quelques années de formation en danse contemporaine, classique, jazz et hip-hop elle décide de faire le saut vers le monde du cirque. À part du mât chinois elle s’enfile aussi en tant qu’agile sur un porteur au guidon d’un vélo acrobatique.",
    "eva_2" : "Elle participe aussi en tant qu’acrobate dans le spectacle Iter 2.0. de la compagnie Infra·poètic·circ. Ainsi que avec la cia. Faltan7 et leur spectacle Express.",
    "eva_3" : "En plus elle est aussi formatrice de cirque et collabore activement au sein de l’associació de les Arts del Circ Cronopis de Mataró.",
    "joan_1" : "Dès son enfance on lui disait qu’il faudrait l’amener au cirque, mais ce n’est qu’après avoir fini ses études en Publicité et Relations Publiques qu’il commença sa formation au cirque. Malgré son amour inconditionnel du mât chinois il officie aussi en tant que porteur au guidon d’un vélo.",
    "joan_2" : "Il collabore habituellement avec la compagnie Planeta Trampolí et c’est aussi un des interprètes du spectacle  Express de la cia. Faltan7.",
    "joan_3" : "Il travaille aussi en tant que formateur de cirque pour enfants et adultes et collabore activement au sein de l’Associació de les Arts del Circ Cronopis de Mataró."
  }
  }
</i18n>
