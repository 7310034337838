import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import i18n from '../i18n';

Vue.use(VueRouter);

const routes = [{
  path: '/:locale?',
  component: Home,
  beforeEnter: (to, from, next) => { // <------------
    const { locale } = to.params; // 1
    if (!locale) {
      i18n.locale = 'ca';
      return next();
    }
    // eslint-disable-next-line camelcase
    const supported_locales = ['es', 'ca', 'en']; // 2
    if (!supported_locales.includes(locale)) return next('ca'); // 3
    if (i18n.locale !== locale) { // 4
      i18n.locale = locale;
    }
    return next();
  },
  children: [],
},
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
