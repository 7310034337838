<template>
  <div class="locale-changer">
    <div class="locale"
         v-bind:class="{ 'active' : $i18n.locale === lang }"
         v-for="(lang, i) in langs" :key="`Lang${i}`"
         @click="changeLocale(lang)">
      {{ lang }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'locale-changer',
  data() {
    return { langs: ['ca', 'es', 'fr', 'en'], stickyEnabled: false };
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    changeLocale(value) {
      this.$i18n.locale = value;
      this.$emit('stiky');
    },
    handleScroll() {
      this.scrollPosition = window.scrollY;
      if (this.scrollPosition >= 75) {
        this.stickyEnabled = true;
      } else {
        this.stickyEnabled = false;
      }
    },
  },
  computed: {
  },
};
</script>

<style lang="scss">
</style>
